<template>
  <ul class="case-list mb-4">
    <li v-for="(caseItem, index) in filtered_searched_data" :key="index" class="case-item">
      <CaseCard :caseData="caseItem" :number="index + 1" :caseType="caseType" :caseTitle="caseTitle" :abstract="abstract"/>
      <img v-if="index < filtered_searched_data.length - 1" src="https://cdn.builder.io/api/v1/image/assets/TEMP/86f8a62eda915b32cea76a2453ac2cc7d4d6e8910796cc2f1e7f7051a59c2468?placeholderIfAbsent=true&apiKey=b7b3d02748134357b1767f32084c4983" alt="" class="divider" />
    </li>
  </ul>
</template>

<script>
import CaseCard from './CaseCard.vue';

export default {
  name: 'CaseList',
  components: {
    CaseCard
  },
  props: {
    caseTitle: String,
    abstract: String,
    filtered_searched_data: {
      type: Array,
      required: true
    },
    caseType: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>
.case-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.case-item {
  margin-top: 24px;
}

.divider {
  width: 100%;
  stroke-width: 1px;
  stroke: #d1d2d5;
  margin-top: 24px;
}

@media (max-width: 991px) {
  .divider {
    max-width: 100%;
  }
}
</style>