<template>
  <div>
    <article
      @click="isExpanded = !isExpanded"
      :class="['case-card', { highlighted: isExpanded === true }]"
    >
      <span class="case-number">{{ number }}</span>
      <div class="case-info">
        <div class="case-content">
          <div class="case-header">
            <p
              class="case-title"
              v-html="highlightedTitle"
              style="font-style: italic"
            ></p>
            <div class="case-meta">
              <span
                class="case-label"
                v-if="caseData.highlights && caseType !== 'Precedents'"
                >Case Title</span
              >
              <RelevancyBadge
                v-if="caseType === 'CaseLawSearch' && caseData.verify !== null"
                :score="Number(caseData.verify)"
              />
              <div v-if="!isExpanded">
                <div v-if="!hasEnoughData">
                  <button
                    v-if="buttonCondition"
                    @click="summarizeCase(caseData.id)"
                    class="view-summary-btn"
                  >
                    <span class="text-de">Summarize Case</span>
                  </button>
                </div>
                <div v-else-if="caseData.completedPerCentage >= 100">
                  <button
                    v-if="buttonCondition"
                    @click="viewSummary(caseData.id)"
                    class="view-summary-btn"
                  >
                    <span class="text-de">View Summary</span>
                  </button>
                </div>

                <div v-else>
                  <button
                    v-if="buttonCondition"
                    @click="handleButtonClick"
                    class="view-summary-btn"
                  >
                    <span v-if="safePercentage <= 0" class="icon-text-wrapper">
                      <span class="material-symbols-rounded rotate-icon"
                        >progress_activity</span
                      >
                      <span class="text-de">Summarizing:</span>
                    </span>

                    <span
                      v-else-if="safePercentage > 0 && safePercentage <= 33.33"
                      class="icon-text-wrapper"
                    >
                      <span class="material-symbols-rounded rotate-icon"
                        >progress_activity</span
                      >
                      <span class="text-de">Extracting key points:</span>
                    </span>

                    <span v-else class="icon-text-wrapper">
                      <span class="material-symbols-rounded rotate-icon"
                        >progress_activity</span
                      >
                      <span class="text-de">Extracting details:</span>
                    </span>

                    <span class="percentage-text"
                      >{{ safePercentage.toFixed(2) }} %</span
                    >
                  </button>
                </div>
              </div>
            </div>
            <p class="details-cutoff" v-if="!isExpanded">
              {{ caseData.highlights }}
            </p>
          </div>
          <button class="expand-btn" aria-label="Expand case details">
            <span v-if="isExpanded === false" class="material-symbols-rounded"
              >keyboard_arrow_down</span
            >
            <span v-else class="material-symbols-rounded"
              >keyboard_arrow_up</span
            >
          </button>
        </div>
      </div>
    </article>
    <section v-show="isExpanded === true" class="case-details">
      <header class="details-header">
        <h4 class="details-title">Case Info</h4>
        <div v-if="!hasEnoughData">
          <button
            v-if="buttonCondition"
            @click="summarizeCase(caseData.id)"
            class="view-summary-btn bg-btn"
          >
            <span>Summarize Case</span>
          </button>
        </div>
        <div v-else-if="caseData.completedPerCentage >= 100">
          <button
            v-if="buttonCondition"
            @click="viewSummary(caseData.id)"
            class="view-summary-btn bg-btn"
          >
            <span>View Summary</span>
          </button>
        </div>

        <div v-else>
          <button
            v-if="buttonCondition"
            @click="handleButtonClick"
            class="view-summary-btn bg-btn"
          >
            <span v-if="safePercentage <= 0" class="icon-text-wrapper">
              <span
                class="material-symbols-rounded rotate-icon"
                style="color: #fff !important"
                >progress_activity</span
              >
              <span>Summarizing:</span>
            </span>

            <span
              v-else-if="safePercentage > 0 && safePercentage <= 33.33"
              class="icon-text-wrapper"
            >
              <span
                class="material-symbols-rounded rotate-icon"
                style="color: #fff !important"
                >progress_activity</span
              >
              <span>Extracting key points:</span>
            </span>

            <span v-else class="icon-text-wrapper">
              <span
                class="material-symbols-rounded rotate-icon"
                style="color: #fff"
                >progress_activity</span
              >
              <span>Extracting details:</span>
            </span>

            <span class="percentage-text"
              >{{ safePercentage.toFixed(2) }} %</span
            >
          </button>
        </div>
      </header>
      <div class="details-content">
        <h5 class="details-subtitle">{{ caseData.name }}</h5>
        <p class="details-text">{{ caseData.highlights ?? "" }}</p>
      </div>
      <div class="topic-section">
        <h5 class="topic-title" v-if="caseData.topic">{{ "Topic" }}</h5>
        <p class="topic-text">{{ caseData.topic ?? "" }}</p>
      </div>
      <div class="abstract-section">
        <h5 class="abstract-title" v-if="caseData.content">Abstract</h5>
        <p
          class="abstract-text"
          v-html="highlightAbstract(caseData.content)"
        ></p>
      </div>
      <div class="jurisdiction-section">
        <div class="jurisdiction-content"></div>
      </div>
      <footer class="case-footer">
        <div v-if="caseData.date" class="decision-date">
          <span class="date-day">{{ formattedDate.day }}</span>
          <span class="date-month-year">{{ formattedDate.date }}</span>
          <span class="date-label">Decision Date</span>
        </div>
        <div class="case-jurisdiction">
          <div class="jurisdiction-info">
            <h6 class="jurisdiction-label">Jurisdiction</h6>
            <p class="jurisdiction-value">
              {{ caseData.type ?? caseData.jurisdiction ?? "" }}
            </p>
          </div>
          <div v-if="caseData.court" class="court-info">
            <h6 class="court-label">Court Name</h6>
            <p class="court-value">{{ caseData.court }}</p>
          </div>
        </div>
      </footer>
    </section>
  </div>
</template>

<script>
import RelevancyBadge from "./RelevancyBadge.vue";
import CaseSearch from "@/store/casesearch.js";
import Research from "@/store/research.js";

export default {
  name: "CaseCard",
  components: {
    RelevancyBadge,
  },
  props: {
    caseTitle: String,
    abstract: String,
    caseData: {
      type: Object,
      required: true,
      default: () => ({
        completedPerCentage: 0,
      }),
    },
    number: {
      type: Number,
      required: true,
    },
    caseType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isExpanded: false,
    };
  },
  computed: {
    formattedDate() {
      const dateString = this.caseData.date;
      if (!dateString) return { day: "", date: "" };
      const [year, month, day] = dateString.split("-");
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const monthName = months[parseInt(month, 10) - 1];
      return {
        day: day,
        date: `${monthName} ${year}`,
      };
    },
    hasEnoughData() {
      return this.caseData.completedPerCentage !== "Not enough data";
    },
    buttonCondition() {
      return (
        (this.caseType === "CaseLawSearch" && this.caseData.verify >= 60) ||
        (this.caseType !== "Precedents" && this.caseType !== "Annotation")
      );
    },
    safePercentage() {
      const percentage = Number(this.caseData.completedPerCentage);
      return isNaN(percentage) ? 0 : percentage;
    },
    highlightedTitle() {
      const title = this.caseData.name ?? this.caseData.title ?? "";

      if (!this.caseTitle) {
        return title;
      }
      const searchTerm = this.caseTitle.toLowerCase();
      const highlighted = title.replace(
        new RegExp(`(${searchTerm})`, "gi"),
        `<span style="background:#FAAD14;">$1</span>`
      );

      return highlighted;
    },
  },
  methods: {
    handleButtonClick() {
      if (this.safePercentage < 100) {
        this.$toast.warning("Still in progress.");
      } else {
        this.viewSummary(this.caseData.id);
      }
    },
    summarizeCase(data) {
      const summarize = (method) => {
        return method(data).then((response) => {
          this.$toast.success(response.data.message);
          this.caseData.completedPerCentage =
            response.data.data.completedPerCentage;
          return response.data.data.completedPerCentage;
        });
      };

      const refetchUntilComplete = async (method) => {
        let complete = false;

        while (!complete) {
          const completedPercentage = await summarize(method);
          if (completedPercentage >= 100) {
            complete = true; // Exit the loop if complete percentage is >= 100
          } else {
            console.log("Complete percentage is not yet 100. Refetching...");
            await new Promise((resolve) => setTimeout(resolve, 2000)); // Wait for 2 seconds before refetching
          }
        }
      };

      if (this.caseType === "CaseLawSearch") {
        refetchUntilComplete(CaseSearch.SummarizeCase).catch((error) => {
          console.log(error);
        });
      } else {
        refetchUntilComplete(Research.SummarizeLegis).catch((error) => {
          console.log(error);
        });
      }
    },
    viewSummary(id) {
      if (this.caseType === "CaseLawSearch") {
        this.$router.push({ name: "CaseLawSearchSummary", params: { id: id } });
      } else {
        this.$router.push({
          name: "LegislationSearchSummary",
          params: { id: id },
        });
      }
    },
    highlightAbstract(content) {
      if (!this.abstract) return content;
      const searchText = this.abstract;
      const regex = new RegExp(`(${searchText})`, "gi");
      return content.replace(
        regex,
        '<span style="background:#FAAD14;">$1</span>'
      );
    },
  },
};
</script>

<style scoped>
.case-card {
  border-radius: 8px;
  display: flex;
  width: 100%;
  align-items: flex-start;
  gap: 16px;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 8px 10px;
  cursor: pointer;
}

.case-card:hover {
  background-color: #f2f3f3;

  &.highlighted {
    background-color: #e8f4ff;
  }
}

.highlighted {
  background-color: #e8f4ff;
}

.case-number {
  border-radius: 50%;
  background-color: #0e4485;
  min-height: 30px;
  color: #fff;
  text-align: center;
  width: 30px;
  height: 30px;
  padding: 0 10px;
  font: 500 14px/30px Poppins, sans-serif;
}

.case-info {
  display: flex;
  min-width: 240px;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
  flex-basis: 20px;
}

.case-content {
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
}

.case-header {
  display: flex;
  min-width: 240px;
  flex-direction: column;
  font-family: Poppins, sans-serif;
  justify-content: flex-start;
  flex: 1;
  flex-basis: 12px;
}

.case-title {
  color: #383a3e;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
}

.case-meta {
  display: flex;
  margin-top: 4px;
  width: 100%;
  align-items: center;
  font-size: 14px;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.case-label {
  color: #86888d;
  font-weight: 400;
  line-height: 24px;
  margin-right: 10px;
}

.case-details {
  border-radius: 8px;
  border: 1px solid var(--Status-Blue, #1890ff) !important;
  background-color: var(--Neutral-White, #fff) !important;
  margin-top: 16px;
  padding: 8px 16px;
}

.details-header {
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: Poppins, sans-serif;
  flex-wrap: wrap;
  justify-content: space-between;
}

.details-title {
  color: var(--Neutral-Black, #383a3e) !important;
  font-weight: 600;
  line-height: 1.4;
  margin: 0;
}

.summarize-button {
  border-radius: 4px;
  background-color: var(--Primary-Blue, #0e4485) !important;
  color: var(--Neutral-White, #fff);
  font-size: 16px;
  font-weight: 500;
  padding: 4px 16px;
  border: none;
  cursor: pointer;
}

.details-content,
.topic-section,
.abstract-section {
  margin-top: 16px;
  font-family: Poppins, sans-serif;
}

.details-subtitle,
.topic-title,
.abstract-title {
  color: var(--Neutral-Black, #383a3e) !important;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  margin: 0 0 4px;
}

.details-text,
.topic-text,
.abstract-text {
  color: var(--Neutral-Dark-Grey, #86888d) !important;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
}

.case-footer {
  display: flex;
  margin-top: 16px;
  gap: 16px;
  flex-wrap: wrap;
}

.decision-date {
  border-radius: 8px;
  background: #fafbfc;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #383a3e;
  padding: 18px;
  font-weight: 500;
  width: 25%;
}

.date-day {
  font-size: 32px;
}

.date-month-year {
  font-size: 16px;
  line-height: 28px;
}

.date-label {
  color: #86888d !important;
  font-size: 14px;
  font-weight: 500;
  margin-top: 8px;
}

.case-jurisdiction {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.jurisdiction-info,
.court-info {
  border-radius: 8px;
  background: #fafbfc;
  padding: 8px;
}

.jurisdiction-label,
.court-label {
  color: #86888d !important;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
}

.jurisdiction-value,
.court-value {
  color: #383a3e;
  font-size: 16px;
  margin: 0;
}

.view-summary-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  border-radius: 4px;
  color: #0e4485;
  font-weight: 500;
  line-height: 1;
  margin-top: 4px;
  padding: 2px 8px;
  background: none;
  border: none;
  cursor: pointer;
}

.bg-btn {
  background: var(--primary) !important;
  color: #fff;
  padding: 6px 16px !important;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.jurisdiction-label,
.court-label {
  color: #86888d;
  font-size: 14px;
  margin: 0;
}

.jurisdiction-value,
.court-value {
  color: #383a3e;
  font-size: 16px;
  margin: 0;
}

.expand-btn {
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  padding: 6px;
  background: none;
  border: none;
  cursor: pointer;
}

.expand-icon {
  width: 24px;
  height: 24px;
}

@media (max-width: 991px) {
  .case-number {
    white-space: initial;
  }

  .case-info,
  .case-header {
    max-width: 100%;
  }
}

.details-cutoff {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--Neutral-Dark-Grey, #86888d) !important;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
}

.icon-text-wrapper {
  display: flex;
  align-items: center;
}

.percentage-text {
  margin-left: 8px;
}

.rotate-icon {
  display: inline-block;
  animation: rotate 1s linear infinite;
  margin-right: 8px;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.text-de {
  text-decoration: underline;
}
</style>
